import axiosInstance from "./instance";
import {
  EmailTemplateInput,
  EventReviewInput,
  ICreateEvent,
  IEvents,
  IRatingsResponse,
  ITransactionsResponse,
} from "../types";
import { objectToUrlSearchParams } from "../helpers/urlParamsToObject";

export const getEvents = async (data: any): Promise<any> => {
  const paramData =
    data.queryKey[1].searchBy && data.queryKey[1].searchParam
      ? data.queryKey[1]
      : {};

  // const params = new URLSearchParams({
  //   ...paramData,
  //   ...(data.queryKey[1].mode ? { mode: data.queryKey[1].mode } : ""),
  //   size: data.queryKey[1].size ?? 10,
  // })

  const params = {
    ...paramData,
    // ...(data.queryKey[1].mode ? { mode: data.queryKey[1].mode } : ""),
    size: data.queryKey[1].size ?? 10,
  };

  // console.log({ params })

  try {
    const response = await axiosInstance({
      url: `event?${objectToUrlSearchParams(params)}`,
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEventById = async (data: any): Promise<IEvents> => {
  try {
    const response = await axiosInstance({
      url: `event/${data.queryKey[1].id}`,
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEventBySlug = async (data: any): Promise<any> => {
  try {
    const response = await axiosInstance({
      url: `event/registration/${data.queryKey[1].slug}`,
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getParticipantByEventId = async (data: any): Promise<any> => {
  const params = new URLSearchParams({
    sessionId: data.sessionId !== "null" ? data.sessionId : "",
    page: data.pageNumber,
    limit: data.pageSize,
    search: data.search,
  });

  try {
    const response = await axiosInstance({
      url: `event/${data.eventId}/participants?${params}`,
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createEvent = async (data: ICreateEvent) => {
  try {
    const response = await axiosInstance({
      url: `event`,
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const updateEvent = async (data: any) => {
  const { id, ...otherEventData } = data;

  try {
    const response = await axiosInstance({
      url: `event/${data.id}`,
      method: "PATCH",
      data: { ...otherEventData },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const applyDiscount = async (data: any): Promise<any> => {
  console.log(data, "api discount");
  try {
    const response = await axiosInstance({
      url: `event/discount/verify`,
      method: "POST",
      data,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPaymentTiers = async (data: any): Promise<any> => {
  try {
    const response = await axiosInstance({
      url: `event/${data.queryKey[1].eventId}/get-payment-tiers`,
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const toggleEnableTicket = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `event/ticket/toggle`,
      method: "PATCH",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getCustomFields = async (data: any): Promise<any> => {
  try {
    const response = await axiosInstance({
      url: `event/${data.queryKey[1].id}/update-custom-fields`,
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateEventCustomFields = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `event/${data.id}/update-custom-fields`,
      method: "PATCH",
      data: data.payload,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteEventCustomField = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `event/${data.id}/update-custom-fields`,
      method: "DELETE",
      data: data.payload,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadEventBanner = async (data: any) => {
  // Save the current Content-Type header value
  const previousContentType = axiosInstance.defaults.headers["Content-Type"];

  // Set the Content-Type header to "multipart/form-data" for this request
  axiosInstance.defaults.headers["Content-Type"] = "multipart/form-data";

  try {
    const response = await axiosInstance({
      url: "event/upload/banner",
      method: "POST",
      data: data,
    });

    // Reset the Content-Type header to its previous value after the request
    axiosInstance.defaults.headers["Content-Type"] = previousContentType;

    return response.data;
  } catch (error) {
    // Reset the Content-Type header in case of an error
    axiosInstance.defaults.headers["Content-Type"] = previousContentType;

    throw error;
  }
};

export const deleteEvent = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `event/${data.id}`,
      method: "DELETE",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getEventInviteByEventId = async (data: any): Promise<any> => {
  try {
    const response = await axiosInstance({
      url: `event/invite/${data.eventId}`, // tentative
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEventInviteById = async (data: any): Promise<any> => {
  try {
    const response = await axiosInstance({
      url: `event/invite/${data.id}`, // tentative
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const eventParticipantEmail = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `event/send-participant-iv-email/${data.id}`,
      method: "PATCH",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const updateEventInvite = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `event/${data.id}`,
      method: "PATCH",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getEventParticipantBySessionId = async (
  data: any
): Promise<any> => {
  const params = new URLSearchParams({
    sessionId: data.queryKey[1].sessionId,
  });

  try {
    const response = await axiosInstance({
      url: `event/${data.queryKey[1].eventId}/participants?${params}`, // tentative
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEventPaymentTierByTierId = async (data: any): Promise<any> => {
  const params = new URLSearchParams({
    tierId: data.tierId,
  });

  try {
    const response = await axiosInstance({
      url: `event/${data.eventId}/participants?${params}`, // tentative
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEventEmailTemplateConfigs = async (data: any): Promise<any> => {
  try {
    const response = await axiosInstance({
      url: `event/email-template/${data.queryKey[1].eventId}`,
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createEmailTemplate = async (
  data: EmailTemplateInput
): Promise<any> => {
  try {
    const response = await axiosInstance({
      url: `event/email-template`,
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const updateEmailTemplate = async (
  data: EmailTemplateInput
): Promise<any> => {
  const { id, ...rest } = data;
  try {
    const response = await axiosInstance({
      url: `event/email-template/${id}`,
      method: "PATCH",
      data: rest,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteEmailTemplate = async (data: {
  id: string;
}): Promise<any> => {
  try {
    const response = await axiosInstance({
      url: `event/email-template/${data.id}`,
      method: "DELETE",
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const reviewEvent = async (data: EventReviewInput): Promise<any> => {
  try {
    const response = await axiosInstance({
      url: `event/rating`,
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getEventFeedbacks = async (
  data: any
): Promise<IRatingsResponse> => {
  const { id, page, limit } = data.queryKey[1];

  try {
    const response = await axiosInstance<any, IRatingsResponse>({
      url: `event/${id}/rating`,
      method: "GET",
      params: {
        page: page || 1,
        limit: limit || 10,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const toggleAllowFeedback = async (data: any): Promise<any> => {
  const { id } = data;
  try {
    const response = await axiosInstance({
      url: `event/toggle-feedback/${id}`,
      method: "PATCH",
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getEventTransactions = async (data: any): Promise<ITransactionsResponse> => {
  const { id } = data.queryKey[1];

  try {
    const response = await axiosInstance<any, ITransactionsResponse>({
      url: `event/transactions/${id}`,
      method: "GET",
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const trackVisitEvent = async (data: any): Promise<any> => {
  const { id } = data;
  try {
    const response = await axiosInstance({
      url: `event/visit/${id}`,
      method: "POST",
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getVisitMetricsEvent = async (data: any): Promise<any> => {
  const { id } = data;
  try {
    const response = await axiosInstance({
      url: `event/visit/${id}`,
      method: "GET",
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getTransactionMetricsEvent = async (data: any): Promise<any> => {
  const { id } = data;
  try {
    const response = await axiosInstance({
      url: `event/transactions/graph/${id}`,
      method: "GET",
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getEventAnalyticsData = async (data: any): Promise<any> => {
  const { id } = data;
  try {
    const response = await axiosInstance({
      url: `event/analytics/${id}`,
      method: "GET",
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteEventSession = async (data: any) => {
  const { eventId, sessionId } = data;

  try {
    const response = await axiosInstance({
      url: `event/${eventId}/session/${sessionId}`,
      method: "DELETE",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const generateEventQrCode = async (data: any): Promise<any> => {
  const { id } = data;
  try {
    const response = await axiosInstance({
      url: `event/qrcode/${id}`,
      method: "GET",
    });

    return response;
  } catch (error) {
    throw error;
  }
};
