import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material"
import { SelectInputProps } from "@mui/material/Select/SelectInput"
import React from "react"
import { SelectInputBox } from "./select.styles"

interface SelectItemsProps {
  name: string
}

type FDSelectProps = SelectProps & {
  hasObjects?: boolean
  selectItems?: string[]
  helperText?: string | false
  selectInputProps?: SelectInputProps
  selectableObjects?: SelectItemsProps[] | any[]
  useValue?: boolean
}

export const FDSelect: React.FC<FDSelectProps> = ({
  hasObjects,
  helperText,
  selectItems,
  selectableObjects,
  useValue = false,
  ...props
}) => {
  return (
    <SelectInputBox>
      <InputLabel>{props.label}</InputLabel>
      <Select
        sx={{
          "& .MuiList-root": {
            borderRadius: "0.5rem !important",
            padding: "0 !important",
          },
        }}
        className="select__input"
        {...props}
      >
        {props.placeholder && (
          <MenuItem
            sx={{
              padding: "0.875rem 1.25rem",
              color: "#787878",
              fontWeight: 600,
              fontSize: "0.875rem",

              "&:hover": {
                color: "#0C0C0C",
                background: "#E2EDFE",
              },
            }}
            disabled
            value="null"
            selected
          >
            {props.placeholder}
          </MenuItem>
        )}
        {hasObjects
          ? selectableObjects?.map((item, idx) => (
              <MenuItem
                sx={{
                  padding: "0.875rem 1.25rem",
                  color: "#787878",
                  fontWeight: 600,
                  fontSize: "0.875rem",

                  "&:hover": {
                    color: "#0C0C0C",
                    background: "#E2EDFE",
                  },
                }}
                value={useValue ? item.value : item.name}
                key={idx}
              >
                {item.name}
              </MenuItem>
            ))
          : selectItems?.map((item, idx) => (
              <MenuItem
                sx={{
                  padding: "0.875rem 1.25rem",
                  color: "#787878",
                  fontWeight: 600,
                  fontSize: "0.875rem",

                  "&:hover": {
                    color: "#0C0C0C",
                    background: "#E2EDFE",
                  },
                }}
                value={item}
                key={idx}
              >
                {item}
              </MenuItem>
            ))}
      </Select>
      {props.error && helperText && (
        <FormHelperText color="danger">{helperText}</FormHelperText>
      )}
    </SelectInputBox>
  )
}
