import { TEventMode, IEvents, TChargeCarrier, ISession } from "../types";
import { ChargeCarrier } from "../types/enums";

export const renderSVG = (svg: any) => {
  return svg;
};

export const calculateDuration = (startTime: any, endTime: any): string => {
  const startDate: any = new Date(startTime);
  const endDate: any = new Date(endTime);

  // Calculate the difference in milliseconds
  const durationInMs = endDate - startDate;

  // Convert milliseconds to hours, minutes, and seconds
  const hours = Math.floor(durationInMs / 3600000); // 1 hour = 3600000 milliseconds
  const minutes = Math.floor((durationInMs % 3600000) / 60000); // 1 minute = 60000 milliseconds
  const seconds = Math.floor((durationInMs % 60000) / 1000); // 1 second = 1000 milliseconds
  // { hours, minutes, seconds }
  const result = `${hours >= 1 ? hours : ""}${
    minutes >= 1 ? `:${minutes}` : ""
  } ${hours > 1 || (hours === 1 && minutes > 1) ? "hours" : "hour"}`;

  return result;
};

export const getDateInString = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

export const sortByMostRecentEvent = (
  events: IEvents[],
  order: "recent" | "old" = "recent"
): IEvents[] => {
  const sortedEvents = events.slice().sort((a, b) => {
    const getDateAndStartTime = (event: IEvents | any) => {
      const session: any = event.sessions[0];
      const date = new Date(session.date);
      const startTime = session.startTime
        ? new Date(session.startTime)
        : new Date(0);
      return date.getTime() + startTime.getTime();
    };

    if (order === "recent") {
      return getDateAndStartTime(a) - getDateAndStartTime(b);
    } else {
      return getDateAndStartTime(b) - getDateAndStartTime(a);
    }
  });

  return sortedEvents;
};

export const isLiveEvent = (event: IEvents): boolean => {
  let isLive = false;
  const currentDate = new Date();

  if (event && event.sessions) {
    const parsedEventDate = new Date(event.sessions[0]?.date as string);

    if (
      parsedEventDate.getFullYear() === currentDate.getFullYear() &&
      parsedEventDate.getMonth() === currentDate.getMonth() &&
      parsedEventDate.getDate() === currentDate.getDate()
    ) {
      isLive = true;
    } else {
      isLive = false;
    }
    // isLive = false;
  }

  return isLive;
};

export const isPastEvent = (event: IEvents | any): boolean => {
  const currentDate = new Date();

  const eventDate = new Date(event?.sessions[event?.sessions.length - 1].date);

  const eventDateOnly = new Date(eventDate.toDateString());
  const currentDateOnly = new Date(currentDate.toDateString());

  return eventDateOnly < currentDateOnly ? true : false;
};

export const getMergedEventDateAndStartTime = (
  eventDate: string,
  eventStartTime?: string
) => {
  const date = new Date(eventDate).toISOString().split("T")[0];
  const startTime = eventStartTime
    ? new Date(eventStartTime).toISOString().split("T")[1]
    : "00:00";
  const mergedDate = new Date(`${date}T${startTime}`);

  // console.log(mergedDate);

  return mergedDate;
};

export const getEventCountdown = (
  eventDate: string,
  eventEndDate: string,
  eventStartTime: string,
  eventEndTime: string
) => {
  const now: Date = new Date();
  const startDate = new Date(eventDate).toISOString().split("T")[0];
  const endDate = new Date(eventEndDate).toISOString().split("T")[0];
  const startTime = new Date(eventStartTime).toISOString().split("T")[1];
  const endTime = new Date(eventEndTime).toISOString().split("T")[1];
  const targetDate = new Date(`${startDate}T${startTime}`);
  const targetEndDate = new Date(`${endDate}T${endTime}`);

  const timeDifference: number = targetDate.getTime() - now.getTime();

  if (timeDifference > 0) {
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} to go`;
    } else if (hours < 1) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} to go`;
    } else {
      return `${hours} hour${hours > 1 ? "s" : ""} to go`;
    }
  } else if (timeDifference === 0) {
    return "Happening now!";
  } else {
    const endTimeDifference: number = targetEndDate.getTime() - now.getTime();

    // console.log(targetEndDate.getTime(), now.getTime(), endTimeDifference);
    if (endTimeDifference > 1) {
      return "Happening now";
    } else {
      return "Event is closed";
    }
  }
};

export const shareOnTwitter = (
  event_title: string,
  event_url: string,
  event_banner: string
) => {
  const title = encodeURIComponent(event_title);
  const data = encodeURIComponent(event_url);
  const image = encodeURIComponent(event_banner);

  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${title}&url=${data}&media=${image}`;

  window.open(twitterShareUrl, "_blank");
};

export const shareOnLinkedIn = (
  event_title: string,
  event_description: string
) => {
  const title = encodeURIComponent(event_title);
  const data = encodeURIComponent(event_description);
  const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&title=${title}&summary=${data}&url=${window.location.href}`;

  window.open(linkedInShareUrl, "_blank");
};

export const generateRandomCharacter = (length_of_string: number) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length_of_string; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};

export const maskString = (str: string) => {
  if (str && str.length < 5) {
    // If the string length is less than 5, return the original string
    return str;
  }

  const firstTwo = str?.slice(0, 2); // Get the first two characters
  const lastThree = str?.slice(-3); // Get the last three characters
  const middleMasked = str.length >= 5 ? "#".repeat(str.length - 5) : "#"; // Create a masked string for the middle part

  const maskedString = `${firstTwo}${middleMasked}${lastThree}`;

  return maskedString;
};

export const hasPermission = (
  userPermissions: string[],
  requiredPermission: string
) => {
  // console.log(userPermissions, requiredPermission)
  // Manage all is for SuperAdmin
  if (userPermissions.includes("MANAGE_ALL")) {
    return userPermissions.includes("MANAGE_ALL");
  } else {
    return userPermissions.includes(requiredPermission);
  }
};

export const generateUniqueReference = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const length = 12; // You can adjust the length of the reference as needed
  let reference = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    reference += characters.charAt(randomIndex);
  }

  // Add a timestamp to make it more unique (optional)
  const timestamp = Date.now();
  reference += timestamp.toString();

  return reference;
};

export const platformTransactionCharge = (
  amount: number,
  processingFeePercentage: number,
  chargeCarrier: TChargeCarrier
) => {
  // 5% platform charge
  const percentageAmountCharge = processingFeePercentage * amount;

  // This transaction charge only applies when the charge carrier is the PARTCIPANT
  if (chargeCarrier === ChargeCarrier.PARTICIPANT) {
    if (amount >= 2000) {
      return percentageAmountCharge + 100;
    } else {
      return percentageAmountCharge;
    }
  } else {
    return 0;
  }
};

export const approximateValueTo1DP = (figure: number) => {
  return Math.round(figure * 100) / 100;
};

export const compareStringArrayOfSameLength = (
  arr1: string[],
  arr2: string[]
) => {
  if (arr1.length === arr2.length) {
    // Sort the arrays to be in the same order
    const sortArr1 = arr1.sort().slice();
    const sortArr2 = arr2.sort().slice();

    return JSON.stringify(sortArr1) === JSON.stringify(sortArr2);
  }
};
